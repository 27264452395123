/* stylelint-disable property-no-vendor-prefix, comment-whitespace-inside, comment-empty-line-before, no-duplicate-selectors, no-descending-specificity -- https://caniuse.com/?search=user-select */

/*****************************************************************************/
/** MAIN *********************************************************************/
/*****************************************************************************/

.qb-drag-handler, .qb-draggable, .query-builder {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .rule--header::after {
    content: "";
    display: table;
    clear: both;
  }
  
  .query-builder {
    overflow: hidden;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.05rem;
    line-height: 1.25;
    margin: 1rem;
  }
  
  .group {
    background: rgb(255 255 255 / 50%);
    border: 1px solid #BCBCBC;
  }
  
  .rule {
    background-color: #EFEFEF;
    border: 1px solid #BCBCBC;
    padding: 0.75rem;
    flex: 1;
    display: flex;
  }
  
  .rule-with-error .rule {
    border: 1px solid #e0a1a1;
  }
  
  .rule--body {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  
  .rule--body--wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: -0.15rem;
  }
  
  .rule--error {
    color: red;
    margin-bottom: -0.4rem;
    margin-top: 0.4rem;
  }
  
  .group-or-rule {
    border-radius: 5px;
    position: relative;
  }
  
  .rule_group {
    background: rgb (255 252 242 / 50%);
    border: 1px solid #f9f1dd;
  }
  
  .locked {
    border: 1px dashed red;
  }
  
  .qb-placeholder {
    border: 1px dashed gray;
  }
  
  /******************************************************************************/
  /** DRAG *********************************************************************/
  /******************************************************************************/

  .query-builder.qb-dragging {
    cursor: grabbing !important;
  }

  .query-builder.qb-dragging button {
    pointer-events: none;
  }
  
  .qb-draggable {
    pointer-events: none;
    position: absolute;
    opacity: 0.7;
    z-index: 1000;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .qb-drag-handler {
    cursor: grabbing;
  }
  
  /******************************************************************************/
  /** TREE LINES *********************************************************************/
  /******************************************************************************/

  .group--header:not(.no--children, .hide--conjs) {
    position: relative;
  }

  .group--header:not(.no--children, .hide--conjs)::before {
    z-index: 0;
    content: "";
    position: absolute;
    border-width: 0 0 0 2px;
    border-color: #ccc;
    border-style: solid;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    top: 20px;
    height: calc(100% + 10px - 20px);
    left: 11px;
    width: 13px;
  }

  .group--header:not(.no--children, .hide--conjs).hide--line::before {
    border-color: rgb(128 128 128 / 10%);
  }

  .group--children > .group-or-rule-container > .group-or-rule::before, .group--children > .group-or-rule-container > .group-or-rule::after {
    content: "";
    position: absolute;
    border-color: #ccc;
    border-style: solid;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .group--children > .group-or-rule-container > .group-or-rule::before {
    top: -1px;
    height: calc(50% + 1px + 1px);
    border-width: 0 0 2px 2px;
  }

  .group--children > .group-or-rule-container > .group-or-rule::after {
    top: calc(50% + 1px);
    height: calc(50% - 1px + 10px + 2px);
    border-width: 0 0 0 2px;
  }

  .group--children > .group-or-rule-container > .group-or-rule::before, .group--children > .group-or-rule-container > .group-or-rule::after {
    left: -14px;
    width: 13px;
  }

  .group--children > .group-or-rule-container:last-child > .group-or-rule::before {
    border-radius: 0 0 0 4px;
  }

  .group--children > .group-or-rule-container:last-child > .group-or-rule::after {
    display: none;
  }

  .group--children.hide--conjs > .group-or-rule-container:first-child > .group-or-rule::before {
    display: none;
  }

  .group--children.hide--conjs > .group-or-rule-container:first-child > .group-or-rule::after {
    border-radius: 4px 0 0;
    border-width: 2px 0 0 2px;
  }

  .group--children.hide--conjs > .group-or-rule-container:first-child > .group-or-rule::after {
    top: calc(50% - 1px);
    height: calc(50% + 1px + 10px + 2px);
  }

  .group--children.hide--line > .group-or-rule-container > .group-or-rule::before, .group--children.hide--line > .group-or-rule-container > .group-or-rule::after {
    border-color: rgb(128 128 128 / 10%);
  }
  
  .rule_group--children > .group-or-rule-container:first-child > .group-or-rule::before {
    display: none;
  }

  .rule_group--children > .group-or-rule-container:first-child > .group-or-rule::after {
    border-radius: 4px 0 0;
    border-width: 2px 0 0 2px;
    top: calc(50% - 1px);
    height: calc(50% + 1px + 5px + 2px);
  }

  .rule_group--children > .group-or-rule-container > .group-or-rule::before {
    top: -1px;
    height: calc(50% + 1px + 1px);
  }

  .rule_group--children > .group-or-rule-container > .group-or-rule::after {
    top: calc(50% + 1px);
    height: calc(50% - 1px + 5px + 2px);
  }

  .rule_group--children > .group-or-rule-container > .group-or-rule::before, .rule_group--children > .group-or-rule-container > .group-or-rule::after {
    left: -11px;
    width: 10px;
  }

  .switch_group > .group--children > .group-or-rule-container > .group-or-rule::before {
    top: -2px;
    height: calc(50% + 1px + 2px);
  }

  .switch_group > .group--children > .group-or-rule-container > .group-or-rule::after {
    top: calc(50% + 1px);
    height: calc(50% - 1px + 10px + 4px);
  }

  .switch_group > .group--children > .group-or-rule-container > .group-or-rule::before, .switch_group > .group--children > .group-or-rule-container > .group-or-rule::after {
    left: -15px;
    width: 13px;
  }

  .switch_group > .group--header:not(.no--children, .hide--conjs)::before {
    top: 20px;
    height: calc(100% + 10px - 20px);
    left: 11px;
    width: 13px;
  }
  
  .qb-draggable::before, .qb-draggable::after {
    display: none;
  }
  
  /******************************************************************************/
  /** GROUP *********************************************************************/
  /******************************************************************************/

  .group--header,
  .group--footer {
    display: flex;
    align-items: stretch;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  
  .group-or-rule-container {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    padding-right: 0.75rem;
  }

  .group-or-rule-container:first-child {
    margin-top: 0 !important;
  }

  .group-or-rule-container:last-child {
    margin-bottom: 0 !important;
  }
  
  .group--drag-handler {
    margin-right: 0.6rem;
    margin-top: 0.25rem;
    position: relative;
    align-self: center;
  }
  
  .group--conjunctions {
    flex-shrink: 0;
    display: flex;
  }

  .group--conjunctions .group--drag-handler {
    margin-left: 0.75rem;
  }
  
  .group--children {
    padding-left: 1.85rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .group--children .group--field--count--rule {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  
  .group--conjunctions.hide--conj {
    opacity: 0.3;
  }
  
  .group--actions {
    margin-left: 0.75rem;
    flex: 1;
    display: flex;
  }

  .group--actions--tl, .group--actions--bl {
    justify-content: flex-start;
  }

  .group--actions--tl {
    margin-left: 1.5rem;
  }

  .group--actions--tc, .group--actions--bc {
    justify-content: center;
  }

  .group--actions--tr, .group--actions--br {
    justify-content: flex-end;
  }
  
  /******************************************************************************/
  /** CASE_GROUP *********************************************************************/
  /******************************************************************************/

  .case_group {
    border-width: 2px;
  }
  
  .case_group--body {
    display: flex;
    flex-direction: row;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .case_group--body .case_group--children {
    flex: auto;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .case_group--body .case_group--value {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.75rem;
  }
  
  /******************************************************************************/
  /** RULE_GROUP *********************************************************************/
  /******************************************************************************/

  .rule_group {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.75rem;
  }

  .rule_group .group--field {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    align-self: center;
  }

  .rule_group .group--actions {
    margin-left: 0.75rem;
    align-self: center;
    flex: 0;
  }

  .rule_group .rule_group--children {
    flex: 1;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    padding-left: 1.4rem;
  }

  .rule_group .rule_group--children .group-or-rule-container {
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    padding-right: 0.4rem;
  }

  .rule_group .rule_group--children.one--child {
    padding-left: 0.7rem;
  }

  /******************************************************************************/
  /** RULE_GROUP_EXT *********************************************************************/
  /******************************************************************************/

  .rule_group_ext .group--actions {
    flex: none;
  }
  
  .group--header.hide--drag.with--conjs > .group--field--count--rule {
    margin-left: 1.5rem;
  }

  /******************************************************************************/
  /** RULE **********************************************************************/
  /******************************************************************************/
  
  .rule--header {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
  }
  
  .rule--drag-handler {
    display: flex;
    align-items: center;
    margin-right: 0.6rem;
  }
  
  .rule--field, .rule--fieldsrc, .group--field, .rule--operator, .rule--value, .rule--operator-options, .rule--widget, .widget--widget, .widget--valuesrc, .widget--sep, .operator--options--sep, .rule--before-widget, .rule--after-widget {
    display: inline-block;
  }
  
  .widget--sep, .operator--options--sep, .rule--func--bracket-before, .rule--func--bracket-after, .rule--func--arg-sep {
    vertical-align: bottom;
    align-self: flex-end;
    margin-bottom: 0.25rem;
  }

  .widget--sep {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  
  .widget--sep.widget--sep-first {
    margin-right: 0.75rem;
    margin-left: 0;
  }
  
  .rule--operator {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  
  .operator--options--sep {
    margin-right: 0.75rem;
  }
  
  .rule--func--arg-sep {
    margin-left: 0.25rem;
    margin-right: 0.45rem;
    margin-bottom: -0.15rem;
  }
  
  .rule--func--bracket-after {
    margin-left: 0.4rem;
    margin-right: 0.15rem;
  }
  
  .rule--func--bracket-before {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  
  .widget--valuesrc {
    vertical-align: top;
  }
  
  .rule--fieldsrc {
    vertical-align: top;
  }
  
  .operator--options {
    display: flex;
  }
  
  .rule--operator-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .rule--operator-text-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  div.tooltip-inner {
    max-width: 500px;
  }
  
  .rule--label {
    display: block;
    font-weight: bold;
  }

  /******************************************************************************/
  /** FUNC **********************************************************************/
  /******************************************************************************/

  .rule--func--wrapper, .rule--func, .rule--func--args, .rule--func--arg, .rule--func--arg-value, .rule--func--bracket-before, .rule--func--bracket-after, .rule--func--arg-sep, .rule--func--arg-label, .rule--func--arg-label-sep {
    display: inline-block;
  }
  
  .rule--func--wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  
  .rule--func--args {
    display: flex;
    flex-wrap: wrap;
  }
  
  .rule--func--arg {
    display: flex;
  }
  
  .rule--field-wrapper,
  .rule--field--func,
  .rule--func--wrapper,
  .rule--func--args,
  .rule--func--arg,
  .rule--func--arg-value,
  .rule--func--arg-value > .rule--widget,
  .widget--func {
    display: contents;
  }
  
  .rule--func, .widget--widget {
    margin-bottom: 0.15rem;
  }

  /******************************************************************************/
  /** Minimalism ****************************************************************/
  /******************************************************************************/

  .qb-lite .group--drag-handler, .qb-lite .group--actions {
    transition: opacity 0.2s;
  }

.qb-lite .group--header:not(:hover) .group--drag-handler,
.qb-lite .group--header:not(:hover) .group--actions,
.qb-lite .rule_group:not(:hover) .group--drag-handler,
.qb-lite .rule_group:not(:hover) .group--actions,
.qb-lite .group--header:hover .group--header .group--drag-handler, 
.qb-lite .group--header:hover .group--header .group--actions {
  opacity: 0.3;
}

  .qb-lite .rule--fieldsrc, .qb-lite .widget--valuesrc, .qb-lite .rule--drag-handler, .qb-lite .rule--header {
    transition: opacity 0.2s;
  }

  .qb-lite .rule:hover .rule .rule--fieldsrc, .qb-lite .rule:hover .rule .widget--valuesrc, .qb-lite .rule:hover .rule .rule--drag-handler, .qb-lite .rule:hover .rule .rule--header,
  .qb-lite .rule:not(:hover) .rule--fieldsrc,
  .qb-lite .rule:not(:hover) .widget--valuesrc,
  .qb-lite .rule:not(:hover) .rule--drag-handler,
  .qb-lite .rule:not(:hover) .rule--header {
    opacity:0;
  }

  .qb-lite.qb-dragging .rule--fieldsrc, .qb-lite.qb-dragging .widget--valuesrc, .qb-lite.qb-dragging .rule--drag-handler, .qb-lite.qb-dragging .rule--header, .qb-lite.qb-dragging .group--drag-handler, .qb-lite.qb-dragging .group--actions {
    opacity: 0 !important;
  }

  /******************************************************************************/
  /** Shrink textarea ***********************************************************/
  /******************************************************************************/

  .rule--body.can--shrink--value .rule--value {
    flex: 1;
  }

  .rule--body.can--shrink--value .rule--value > .rule--widget {
    width: 100%;
    display: flex;
  }

  .rule--body.can--shrink--value .rule--value > .rule--widget .widget--widget {
    flex: 1;
  }

  /*********************************************************************/
  /** Button ***********************************************************/
  /*********************************************************************/

  .group-or-rule .group--actions .btn {
    margin: 0 0.75rem;
    border-radius: 5px !important;
  }