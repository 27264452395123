/* stylelint-disable no-duplicate-selectors -- No way to balance this AND no-descending-specificity at the same time. */
// Nav
// Nav pills
.nav-pills {
  .nav-item {
    margin-right: 0.5rem;
  }
}

// Stretch items
.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .nav-link {
    display: flex;
    align-items: center;
  }
}

// Adjust the order for .nav-pills .nav-item:last-child
.nav-pills {
  .nav-item {
    &:last-child {
      margin-right: 0;
    }
  }
}

// Nav group
.nav-group {
  padding: 0.35rem;

  @include border-radius($border-radius);

  background-color: var(--#{$prefix}gray-100);

  // Outline nav group
  &.nav-group-outline {
    background-color: transparent;
    border: 1px solid var(--#{$prefix}border-color);
  }

  // Fluid option
  &.nav-group-fluid {
    display: flex;

    > label,
    > .btn {
      position: relative;
      flex: 1 0 0;
    }

    > label {
      margin-right: 0.1rem;

      > .btn {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
