//
// Buttons
//

// Import Dependencies
@import 'buttons/base';
@import 'buttons/theme';

.channel-icon {
  height: 32px;
}

[data-bs-theme="dark"] .channel-icon, 
[data-bs-theme="light"] .channel-button.btn-primary .channel-icon {
  filter: invert(98%) sepia(2%) saturate(170%) hue-rotate(276deg) brightness(116%) contrast(87%);
}

.channel-button {
  text-transform: capitalize; 
  border: 1px solid #eee !important; 
  border-radius: 8px; 
  margin: 0.75rem; 
  width: 100%; 
  text-align: left; 
  padding: 0.75rem !important;
}