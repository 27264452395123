/* Vendor prefix for placeholder removed as it only impacted 0.12% globally */
//
// Input placeholder color
//

@mixin placeholder($color) {
  // Chrome, Firefox, Opera, Safari 10.1+
  &::placeholder {
    color: $color;
  }
}