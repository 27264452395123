// Header

// General mode
.app-header-menu {
  .menu-extended {
    --#{$prefix}menu-link-bg-color-active: rgb(
      var(--#{$prefix}gray-100-rgb) 0.7
    );
    --#{$prefix}menu-link-bg-color-hover: rgb(
      var(--#{$prefix}gray-100-rgb) 0.7
    );

    .menu-custom-icon {
      background-color: var(--#{$prefix}gray-100);
    }

    .menu-link {
      &.active,
      &:hover {
        .menu-custom-icon {
          background-color: var(--#{$prefix}gray-200);
        }
      }
    }
  }

  .menu {
    // General
    .menu-item {
      @include menu-link-default-state(
        $title-color: var(--#{$prefix}gray-700),
        $icon-color: var(--#{$prefix}gray-500),
        $bullet-color: var(--#{$prefix}gray-500),
        $arrow-color: var(--#{$prefix}gray-500),
        $bg-color: null
      );
      @include menu-link-hover-state(
        $title-color: var(--#{$prefix}primary),
        $icon-color: var(--#{$prefix}primary),
        $bullet-color: var(--#{$prefix}primary),
        $arrow-color: var(--#{$prefix}primary),
        $bg-color: null
      );
      @include menu-link-show-state(
        $title-color: var(--#{$prefix}primary),
        $icon-color: var(--#{$prefix}primary),
        $bullet-color: var(--#{$prefix}primary),
        $arrow-color: var(--#{$prefix}primary),
        $bg-color: null
      );
      @include menu-link-here-state(
        $title-color: var(--#{$prefix}primary),
        $icon-color: var(--#{$prefix}primary),
        $bullet-color: var(--#{$prefix}primary),
        $arrow-color: var(--#{$prefix}primary),
        $bg-color: null
      );
      @include menu-link-active-state(
        $title-color: var(--#{$prefix}primary),
        $icon-color: var(--#{$prefix}primary),
        $bullet-color: var(--#{$prefix}primary),
        $arrow-color: var(--#{$prefix}primary),
        $bg-color: var(--#{$prefix}gray-100)
      );
    }
    // Root
    > .menu-item {
      > .menu-link {
        > .menu-title {
          font-weight: $font-weight-bold;
          font-size: 1.1rem;
        }
      }
    }
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .app-header-menu {
    .menu {
      // Menu root item
      > .menu-item {
        margin-right: 0.5rem;
        
        @include menu-link-here-state(
          $title-color: var(--#{$prefix}primary),
          $icon-color: var(--#{$prefix}primary),
          $bullet-color: var(--#{$prefix}primary),
          $arrow-color: var(--#{$prefix}primary),
          $bg-color: var(--#{$prefix}gray-100)
        );

        > .menu-link {
          padding-top: 0.675rem;
          padding-bottom: 0.675rem;
          font-weight: $font-weight-semibold;
        }
      }
    }
  }

  [data-kt-app-layout="dark-sidebar"] {
    .app-header-menu {
      .menu {
        // Menu root item
        > .menu-item {
          &.active,
          &.here {
            > .menu-link {
              background-color: transparent;
              box-shadow: 0 3px 4px rgb(0 0 0 / 3%);
            }
          }
        }
      }
    }
  }

  [data-kt-app-toolbar-fixed="true"] {
    .app-header {
      background-color: var(--#{$prefix}app-header-minimize-bg-color);
    }
  }
}

// Dark mode
@include color-mode(dark) {
  // Desktop mode
  @include media-breakpoint-up(lg) {
    [data-kt-app-layout="dark-sidebar"] {
      .app-header-menu {
        .menu {
          // Menu root item
          > .menu-item {
            &.active,
            &.here {
              > .menu-link {
                box-shadow: none;
                background-color: var(--#{$prefix}gray-100);
              }
            }
          }
        }
      }
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-header {
    background-color: var(--#{$prefix}app-header-minimize-bg-color);
    border-bottom: 1px solid var(--#{$prefix}border-color) !important;
  }

  [data-kt-app-layout="dark-sidebar"] {
    .app-header-menu {
      .menu {
        // Menu root item
        > .menu-item {
          &.active,
          &.here {
            > .menu-link {
              background-color: transparent;
              box-shadow: 0 3px 4px rgb(0 0 0 / 3%);
            }
          }
        }
      }
    }
  }

  [data-kt-app-toolbar-fixed="true"] {
    .app-header {
      background-color: var(--#{$prefix}app-header-minimize-bg-color);
    }
  }
}

// Dark mode
@include color-mode(dark) {
  // Desktop mode
  @include media-breakpoint-up(lg) {
    [data-kt-app-layout="dark-sidebar"] {
      .app-header-menu {
        .menu {
          // Menu root item
          > .menu-item {
            &.active,
            &.here {
              > .menu-link {
                box-shadow: none;
                background-color: var(--#{$prefix}gray-100);
              }
            }
          }
        }
      }
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-header {
    background-color: var(--#{$prefix}app-header-minimize-bg-color);
    border-bottom: 1px solid var(--#{$prefix}border-color) !important;
  }
}

button.menu-link {
    background: inherit;
    border: inherit;
    font-weight: inherit;
    width: 100%;
}

button.menu-link:hover {
transition: color 0.2s ease;
}