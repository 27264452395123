//
// Light Sidebar(used by Light Sidebar layout only)
//

[data-kt-app-layout='light-sidebar'] {
  .app-sidebar {
    background-color: var(--#{$prefix}app-sidebar-light-bg-color);
    border-right: 1px solid var(--#{$prefix}gray-200);

    .scroll-y,
    .hover-scroll-overlay-y {
      @include scrollbar-color(
        transparent,
        var(--#{$prefix}app-sidebar-light-scrollbar-color-hover)
      );
    }

    .app-sidebar-logo {
      border-bottom: 0;
    }

    .app-sidebar-toggle {
      border-radius: 50%;
      box-shadow: none !important;
    }

    .menu {
      font-weight: $font-weight-semibold;

      .menu-item {
        .menu-heading {
          color: var(--#{$prefix}app-sidebar-light-menu-heading-color) !important;
        }

        @include menu-link-default-state(
          $title-color: var(--#{$prefix}app-sidebar-light-menu-link-color),
          $icon-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $bullet-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $arrow-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $bg-color: null
        );
        @include menu-link-hover-state(
          $title-color: var(--#{$prefix}app-sidebar-light-menu-link-color),
          $icon-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $bullet-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $arrow-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $bg-color: null
        );
        @include menu-link-show-state(
          $title-color: var(--#{$prefix}app-sidebar-light-menu-link-color),
          $icon-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $bullet-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $arrow-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $bg-color: null
        );
        @include menu-link-here-state(
          $title-color: var(--#{$prefix}app-sidebar-light-menu-link-color),
          $icon-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $bullet-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $arrow-color: var(--#{$prefix}app-sidebar-light-menu-link-icon-color),
          $bg-color: null
        );
        @include menu-link-active-state(
          $title-color: var(--#{$prefix}primary),
          $icon-color: var(--#{$prefix}primary),
          $bullet-color: var(--#{$prefix}primary),
          $arrow-color: var(--#{$prefix}primary),
          $bg-color: var(--#{$prefix}app-sidebar-light-menu-link-bg-color-active)
        );

        .menu-sub {
          .menu-item {
            .menu-link {
              .menu-title {
                color: #4b5675;
              }

              &.active {
                .menu-title {
                  color: var(--#{$prefix}primary);
                }
              }
            }
          }
        }
      }
    }
  }
}

[data-kt-app-layout='light-sidebar'][data-kt-app-header-fixed='true'] {
  [data-kt-app-header-minimize='on'] .app-header {
    transition: none;
    z-index: 100;
    background-color: var(--bs-app-header-minimize-bg-color);
    box-shadow: var(--bs-app-header-minimize-box-shadow);
  }

  .app-sidebar {
    .app-sidebar-logo {
      border-bottom: 0;
    }
  }
}
