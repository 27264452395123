.copyEmbedCodeIcon {
    cursor: pointer;
}

.copyIconContainer {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    height: 100%;
}

.iconBtn {
    padding: 0.25rem;
    color: var(--bs-gray-600);
}

.iconBtn:hover {
    color: var(--bs-gray-900);
}

.copyBtn {
    border: none;
    background-color: transparent;
}