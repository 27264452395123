.copyBtn {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
}

.settingsBtnDanger {
  background-color: #ee2d41  !important;
}

.btn.btn-danger.settingsBtnDanger:hover {
  background-color: #f1416c  !important;
}