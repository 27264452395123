$spacers: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.15rem,
  5: 1.25rem
);

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@mixin generate-spacing-utilities($property, $abbr) {
  @each $size, $length in $spacers {
    .#{$abbr}-#{$size} {
      #{$property}: #{$length} !important;
    }
    .#{$abbr}t-#{$size} {
      #{$property}-top: #{$length} !important;
    }
    .#{$abbr}r-#{$size} {
      #{$property}-right: #{$length} !important;
    }
    .#{$abbr}b-#{$size} {
      #{$property}-bottom: #{$length} !important;
    }
    .#{$abbr}l-#{$size} {
      #{$property}-left: #{$length} !important;
    }
    .#{$abbr}x-#{$size} {
      #{$property}-left: #{$length} !important;
      #{$property}-right: #{$length} !important;
    }
    .#{$abbr}y-#{$size} {
      #{$property}-top: #{$length} !important;
      #{$property}-bottom: #{$length} !important;
    }
  }

  @each $breakpoint, $breakpoint-value in $breakpoints {
    @if $breakpoint != xs {
      @media (min-width: #{$breakpoint-value}) {
        @each $size, $length in $spacers {
          .#{$abbr}-#{$breakpoint}-#{$size} {
            #{$property}: #{$length} !important;
          }
          .#{$abbr}t-#{$breakpoint}-#{$size} {
            #{$property}-top: #{$length} !important;
          }
          .#{$abbr}r-#{$breakpoint}-#{$size} {
            #{$property}-right: #{$length} !important;
          }
          .#{$abbr}b-#{$breakpoint}-#{$size} {
            #{$property}-bottom: #{$length} !important;
          }
          .#{$abbr}l-#{$breakpoint}-#{$size} {
            #{$property}-left: #{$length} !important;
          }
          .#{$abbr}x-#{$breakpoint}-#{$size} {
            #{$property}-left: #{$length} !important;
            #{$property}-right: #{$length} !important;
          }
          .#{$abbr}y-#{$breakpoint}-#{$size} {
            #{$property}-top: #{$length} !important;
            #{$property}-bottom: #{$length} !important;
          }
        }
      }
    } 
    
    @else {
      @each $size, $length in $spacers {
        .#{$abbr}-#{$breakpoint}-#{$size} {
          #{$property}: #{$length} !important;
        }
        .#{$abbr}t-#{$breakpoint}-#{$size} {
          #{$property}-top: #{$length} !important;
        }
        .#{$abbr}r-#{$breakpoint}-#{$size} {
          #{$property}-right: #{$length} !important;
        }
        .#{$abbr}b-#{$breakpoint}-#{$size} {
          #{$property}-bottom: #{$length} !important;
        }
        .#{$abbr}l-#{$breakpoint}-#{$size} {
          #{$property}-left: #{$length} !important;
        }
        .#{$abbr}x-#{$breakpoint}-#{$size} {
          #{$property}-left: #{$length} !important;
          #{$property}-right: #{$length} !important;
        }
        .#{$abbr}y-#{$breakpoint}-#{$size} {
          #{$property}-top: #{$length} !important;
          #{$property}-bottom: #{$length} !important;
        }
      }
    }
  }
}

@include generate-spacing-utilities(margin, m);
@include generate-spacing-utilities(padding, p);
