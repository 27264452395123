//
// Dynamical column breaks
//
// Custom styling
// Created at: 9-20-2023
// Created by: Gabor Pecsek


// Customize column breaks for desktop mode
@include media-breakpoint-up(xl) {
    .columns-break-lg-1 {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, [col-start] 100% [col-end]) !important;
    }

    .columns-break-lg-2 {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, [col-start] 50% [col-end]) !important;
    }

    .columns-break-lg-3 {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, [col-start] 33% [col-end]) !important;
    }
}

// Customize column breaks for table mode
@include media-breakpoint-down(xl) {
    .columns-break-md-1 {
        display: grid;
        grid-template-columns: repeat(auto-fill, [col-start] 100% [col-end]);
    }

    .columns-break-md-2 {
        display: grid;
        grid-template-columns: repeat(auto-fill, [col-start] 50% [col-end]);
    }

    .columns-break-md-3 {
        display: grid;
        grid-template-columns: repeat(auto-fill, [col-start] 33% [col-end]);
    }
}

// Customize column breaks for mobile mode
@include media-breakpoint-down(md) {
    .columns-break-sm-1 {
        display: grid;
        grid-template-columns: repeat(auto-fill, [col-start] 100% [col-end]);
    }

    .columns-break-sm-2 {
        display: grid;
        grid-template-columns: repeat(auto-fill, [col-start] 50% [col-end]);
    }

    .columns-break-sm-3 {
        display: grid;
        grid-template-columns: repeat(auto-fill, [col-start] 33% [col-end]);
    }
}