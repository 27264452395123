/* stylelint-disable no-descending-specificity -- No sane way to balance collapse and toggle specificity without complete rewrite. */
//
// Accordion
//

// Base
.accordion {
  // Accordion heading
  .accordion-header {
    cursor: pointer;
  }

  // Icon collapse mode
  &.accordion-icon-collapse {
    // Accordion icon expanded mode
    .accordion-icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      transition: $transition-base;

      i,
      .svg-icon {
        color: var(--#{$prefix}primary);
      }

      .accordion-icon-on {
        display: inline-flex;
      }

      .accordion-icon-off {
        display: none;
      }
    }

    // Accordion icon collapsed mode
    .collapsed {
      .accordion-icon {
        transition: $transition-base;

        i,
        .svg-icon {
          color: var(--#{$prefix}text-muted);
        }

        .accordion-icon-on {
          display: none;
        }

        .accordion-icon-off {
          display: inline-flex;
        }
      }
    }
  }

  // Icon toggle mode
  &.accordion-icon-toggle {
    // Accordion icon expanded mode
    .accordion-icon {
      display: flex;
      flex-shrink: 0;
      transition: $transition-base;
      transform: rotate(90deg);
      align-items: center;
      justify-content: center;

      i,
      .svg-icon {
        color: var(--#{$prefix}primary);
      }
    }

    // Accordion icon collapsed mode
    .collapsed {
      .accordion-icon {
        transition: $transition-base;
        transform: rotate(0);

        i,
        .svg-icon {
          color: var(--#{$prefix}text-muted);
        }
      }
    }
  }

  // Reset accordion item border
  &.accordion-borderless {
    // Accordion item
    .accordion-item {
      border: 0;
    }
  }

  // Reset accordion item border, border radius, and background color
  &.accordion-flush {
    // Accordion item
    .accordion-item {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

