// Mobile-specific fixes
@media only screen and (max-width: 575px) {
  .order-sm-1 {
    order: 1 !important;
  }
  
  .order-sm-2 {
    order: 2 !important;
  }
  
  .order-sm-3 {
    order: 3 !important;
  }
  
  .order-sm-4 {
    order: 4 !important;
  }

  .query-builder,
  .querybuilder-container,
  .query-builder .group-or-rule-container {
    padding: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
  }

  .querybuilder-rules {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

