//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #fff;

// Gray colors
$gray-100-dark: #1b1b29;
$gray-200-dark: #2b2b40;
$gray-300-dark: #323248;
$gray-400-dark: #474761;
$gray-500-dark: #565674;
$gray-600-dark: #6d6d80;
$gray-700-dark: #92929f;
$gray-800-dark: #cdcdde;
$gray-900-dark: #fff;

// Theme colors
// Primary
$primary: #2E4EA2;
$primary-active: #0095e8;
$primary-light: #f1faff;
$primary-light-dark: #f1faff;
$primary-inverse: $white;

// Success
$success: #50CD89;
$success-active: #E8FFF3;
$success-light: #e8fff3;
$success-light-dark: #E8FFF3;
$success-inverse: $white;

// Info
$info: #7239EA;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #5014D0;
$info-inverse: $white;

// Danger
$danger: #F1416C;
$danger-active: #fff5f8;
$danger-light: #fff5f8;
$danger-light-dark: #fff5f8;
$danger-inverse: $white;

// Warning
$warning: #F6C000;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #E9B500;
$warning-inverse: $white;

// Card Box Shadow
$card-border-enabled: true;
$card-box-shadow: 0 4px 12px 0 rgb(0 0 0 / 3%);
$card-box-shadow-dark: none;

// Body
$body-bg-dark: #1c1c1c;

//Dark Mode Specifics
$bs-link-color-rgb: rgb(76 112 205);
