//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .app-content {
    padding: $app-content-padding-y $app-content-padding-x $app-content-padding-y $app-content-padding-x;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-content {
    max-width: none;
    padding: $app-content-padding-y-mobile $app-content-padding-x-mobile $app-content-padding-y-mobile $app-content-padding-x-mobile;
  }
}
