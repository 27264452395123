.checkbox {
  border: 1px solid var(--bs-gray-300);
  border-radius: 10px;
  margin: 0.5rem;
  padding: 1rem 1rem 1rem 3.25rem;
  max-width: 275px;
  width: 100%;
  min-height: 125px;
}

.checkboxActive {
  border: 1px solid #2e4ea2;
  border-radius: 10px;
  margin: 0.5rem;
  padding: 1rem 1rem 1rem 3.25rem;
  max-width: 275px;
  width: 100%;
  min-height: 125px;
}

.title {
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--bs-dark);
}
