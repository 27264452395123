.password-form-field-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.profile-copy-to-clipboard-icon {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.profile-generate-password-icon {
  position: absolute;
  right: 90px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.profile-show-password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

html[data-bs-theme="dark"] .text-warning-dark {
  color: #ffffff !important;
}
