//
// Form controls
//

// Customization
.form-control, .react-datepicker-wrapper {
  // Transparent style
  &.form-control-transparent {
    background-color: transparent;
    border-color: transparent;

    &:active,
    &.active,
    &:focus,
    &.focus,
    .dropdown.show > & {
      background-color: transparent;
      border-color: transparent;
    }
  }

  // Flush
  &.form-control-flush {
    @include input-reset();
  }
}

// Solid style
.form-control.form-control-solid {
  background-color: #F9F9F9;
  border-color: $input-solid-bg;
  color: #5B5B5B;

  @include placeholder($input-solid-placeholder-color);

  transition: $transition-input;

  &:active,
  &.active,
  &:focus,
  &.focus,
  .dropdown.show > & {
    background-color: #F9F9F9;
    border-color: $input-solid-bg-focus;
    color: #5B5B5B;
    transition: $transition-input;
  }
}

// Form control solid bg
.form-control-solid-bg {
  background-color: $input-solid-bg;
}

// Readonly controls as plain text
.form-control-plaintext {
  color: $input-plaintext-color;
}

// Placeholder colors
.placeholder-gray-500 {
  @include placeholder(var(--#{$prefix}gray-500));
}

.placeholder-white {
  @include placeholder($white);
}

// Textarea reset resize
.resize-none {
  resize: none;
}

// Fix react-datepicker to act like other form fields
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 0.775rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-gray-300);
  border-radius: 0.475rem;
  box-shadow: false;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
