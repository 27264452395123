.passwordFieldContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.showPasswordIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.pr30px {
  padding-right: 30px;
}
