//
// Code
//

.code, code:not([class*='language-']) {
  font-weight: $code-font-weight;
  color: var(--#{$prefix}code-color);
  line-height: inherit;
  font-size: inherit;
  background-color: var(--#{$prefix}code-bg);
  padding: $code-padding;
  margin: $code-margin;
  box-shadow: var(--#{$prefix}code-box-shadow);
  font-family: $font-family-code;
  @include font-size(1em); // Correct the odd `em` font sizing in all browsers.

  @include border-radius($code-border-radius);
}
