.tz-component {
  max-width: 620px;
}

.tz-component .react-select__control {
  border-color: #ededed;
  background-color: white;
  min-width: 240px;
  border-radius: 0;
}

.tz-component .react-select__control--is-focused,
.tz-component .react-select__control:hover {
  box-shadow: none;
  border: 1px solid #007BC2 !important;
}

.tz-component .react-select__menu {
  border-radius: 0;
}

.tz-component .react-select__group {
  padding-bottom: 0;
}

.tz-component .react-select__group-heading {
  font-weight: bold;
  text-transform: none;
  font-size: 0.875rem; /* 14px to rem */
  color: #212529;
}

.tz-component .react-select__value-container {
  padding: 0.5625rem; /* 9px to rem */
}

.tz-component .react-select__placeholder {
  color: #9d9d9d;
}

.tz-component .react-select__option {
  cursor: pointer;
  outline: 0;
  display: flex !important;
  margin-bottom: 0.5rem;
}

.tz-component .react-select__option:last-child {
  margin-bottom: 0;
}

.tz-component .react-select__option--is-selected {
  background-color: #007BC2!important;
  color: white !important;
}

.tz-component .react-select__option:hover,
.tz-component .react-select__option--is-focused {
  background-color: #f4f4f4;
}

.tz-component .react-select__indicator-separator {
  background-color: #ededed;
}

.tz-groupLabel {
  font-weight: bold;
  text-transform: none;
  font-size: 0.875rem; /* 14px to rem */
  color: #212529;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tz-groupOptions {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.tz-optionTime {
  margin-left: auto;
}

.tz-dropdownIndicator {
  height: 24px;
  width: 32px;
  color: #ccc;
}

.tz-dropdown {
  position: relative;
}

.tz-menu {
  position: absolute;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 8%);
  margin-top: 8px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 8%);
  display: none;
  min-width: 420px;
}

.tz-blanket {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
}

.tz-isOpen .tz-menu,
.tz-isOpen .tz-blanket {
  display: block;
}

.tz-menuWrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.tz-btn {
  -webkit-box-align: baseline;
  align-items: baseline;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  max-width: 100%;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  color: #505f79;
  background: rgba(9, 30, 66, 4%);
  border-radius: 0.475rem;
  border-width: 0;
  margin: 0;
  padding: 0.775rem 1rem;
  text-decoration: none;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  outline: none !important;
  border-color: #d8d8d8 #d1d1d1 #bababa;
  border-style: solid;
}

.tz-btn:hover,
.tz-btn.tz-btnIsSelected {
  color: #505f79 !important;
  background: rgba(9, 30, 66, 8%) !important;
}

.tz-btnContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}

.tz-btnLabel {
  align-self: center;
  margin: 0 0.25rem; /* 4px to rem */
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
}

.tz-btnIconAfter {
  align-self: center;
  display: flex;
  flex-shrink: 0;
  line-height: 0;
  font-size: 0;
  margin: 0 0.25rem; /* 4px to rem */
  user-select: none;
}

html[data-bs-theme="dark"] .tz-btn {
  color: #383838 !important;
  background: rgba(211, 214, 218, 0.966) !important;
}

html[data-bs-theme="dark"] .tz-btn:hover {
  color: #383838 !important;
  background: rgba(168, 170, 173, 0.966) !important;
}

html[data-bs-theme="dark"] .tz-menu {
  color: #383838 !important;
  background: rgba(231, 231, 231, 1) !important;
}