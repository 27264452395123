// Custom styling
// Created at: 9-20-2023
// Created by: Gabor Pecsek

.text-disabled {
  color: #e7e7e7 !important;
}

[data-bs-theme="light"] .text-muted:not(.menu-section) {
  color: #707099 !important;
}

[data-bs-theme="dark"] .text-muted:not(.menu-section) {
  color: #ccc !important;
}

[data-bs-theme="dark"] .text-muted.menu-section {
  color: #aaa !important;
}

[data-bs-theme="dark"] .btn-color-white {
  background: #2b2b40 !important;
  color: #fff !important;
}

[data-bs-theme="dark"] form [class$="-menu"] {
  background-color: var(--bs-body-bg);
  color: var(--bs-gray-700);
}

[data-bs-theme="dark"] form [class^=" css-"][class$="control"] {
  border: 1px solid var(--bs-gray-300);
  border-radius: 0.475rem;
  background-color: var(--bs-body-bg);
}

[data-bs-theme="dark"] form [class^=" css-"][class$="multiValue"],
[data-bs-theme="dark"] form [class^=" css-"][class$="MultiValueGeneric"],
[data-bs-theme="dark"] form [class^=" css-"][class$="MultiValueRemove"] {
  background-color: #333 !important;
  color: #fff !important;
}

[data-bs-theme="dark"] .app-main a,
[data-bs-theme="dark"]
  .menu-state-primary
  .menu-item
  .menu-link.active
  .menu-title,
[data-bs-theme="dark"] .menu-state-primary .menu-item .menu-link.active,
[data-bs-theme="dark"]
  .menu-state-primary
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled, .active, .here),
[data-bs-theme="dark"]
  .menu-state-primary
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled, .active, .here),
[data-bs-theme="dark"]
  .menu-state-primary
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled, .active, .here)
  .menu-title,
[data-bs-theme="dark"]
  .menu-state-primary
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled, .active, .here)
  .menu-title {
  color: #d1e0f0 !important;
}


[data-bs-theme="dark"] .app-main a:hover {
  color: #e5eef5 !important;
  text-decoration: underline !important;
}
