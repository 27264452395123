//
// Base breadcrumb styling
//

.breadcrumb {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;

  // Breadcrumb item styling
  .breadcrumb-item {
    display: flex;
    align-items: center;
    padding-left: $breadcrumb-item-padding-x; // Assumed to be in rem, em, or %

    // General ::after for breadcrumb items
    &::after {
      padding-left: $breadcrumb-item-padding-x; // Assumed to be in rem, em, or %
      content: "/";
    }

    &:first-child,
    &:first-of-type {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }

    &::before {
      display: none;
    }
  }

  //Modifier: Breadcrumb line style
  &.breadcrumb-line .breadcrumb-item::after {
    content: "-";
  }

  // Modifier: Breadcrumb dot style
  &.breadcrumb-dot .breadcrumb-item::after {
    content: "\2022";
  }

  // Modifier: Breadcrumb separatorless style
  &.breadcrumb-separatorless .breadcrumb-item::after {
    display: none;
  }
}
