// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
  appearance: none;
  //box-shadow: none !important;

  // Solid style
  &.form-select-solid {
    @include placeholder($input-solid-placeholder-color);

    background-color: #F9F9F9;
    border-color: $input-solid-bg;
    color: #5B5B5B;
    transition: $transition-input;

    &:active,
    &.active,
    &:focus,
    &.focus,
    .dropdown.show > & {
      background-color: #F9F9F9;
      border-color: $input-solid-bg-focus !important;
      color: #5B5B5B;
      transition: $transition-input;
    }
  }

  // Transparent style
  &.form-select-transparent {
    @include placeholder($input-placeholder-color);

    background-color: transparent;
    border-color: transparent;
    color: $input-color;

    &:active,
    &.active,
    &:focus,
    &.focus,
    .dropdown.show > & {
      background-color: transparent;
      border-color: transparent !important;
      color: $input-color;
    }
  }

  &:focus {
    border-color: $form-select-focus-border-color;

    @if $enable-shadows {
      @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
    } 
    
    @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $form-select-focus-box-shadow;
    }
  }

  &:disabled {
    color: $form-select-disabled-color;
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    text-shadow: 0 0 0 $form-select-color;
  }
}

.flex-help > div {flex-grow: 1;}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.spin {
    animation: spin 1.25s linear infinite;
}
