//
//  Format logs
//
// Custom styling
// Created at: 11-28-2023
// Created by: Gabor Pecsek

.kt_app_logger {
  max-width: 1320px;
  border: 1px solid #ff0;
  background: #ffe;
  margin: 1.5em auto;
  font-family: ui-monospace, "Cascadia Code", "Source Code Pro", Menlo, Consolas,
    "DejaVu Sans Mono", monospace;
  font-size: 1.1rem;
  line-height: 180%;
  padding: 1em 0;
  max-height: 60vh;
  overflow-y: scroll;
  scrollbar-color: #ffff60 #ffe !important;
}

@include media-breakpoint-down(md) {
  .kt_app_logger {
    max-height: 40vh;
  }
}

.kt_app_logger ul {
  list-style-type: none;
}

.kt_app_logger::-webkit-scrollbar { 
    width: 12px; 
} 

.kt_app_logger::-webkit-scrollbar-track { 
    background: #ffe; 
} 

.kt_app_logger::-webkit-scrollbar-thumb { 
    background: #ffff60; 
    border: 3px solid #ffe;
    border-radius: 12px;
}

.kt_app_logger::-webkit-scrollbar-thumb:hover { 
  background: #ffff60; 
} 

.kt_app_logger::-webkit-scrollbar-button { 
    display: none; 
} 