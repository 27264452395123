/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
:root {
  --var-bar-1-bg: #3a834b;
  --var-bar-2-bg: #3a834b;
  --var-bar-3-bg: linear-gradient(45deg, #3a834b, #2e4ea2);
  --var-bar-4-bg: #2e4ea2;
  --var-bar-5-bg: #2e4ea2;
  --var-bar-ball-bg: #2e4ea2;
  --var-body-main-bg: #f5f5f5;
  --var-loaderText-color: #2e2e2e;
}

@media (prefers-color-scheme: dark) {
  :root {
    --var-bar-1-bg: #fff;
    --var-bar-2-bg: #fff;
    --var-bar-3-bg: #fff;
    --var-bar-4-bg: #fff;
    --var-bar-5-bg: #fff;
    --var-bar-ball-bg: #fff;
    --var-body-main-bg: #000;
    --var-loaderText-color: #ccc;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  /*position: absolute;*/
}

.loader {
  position: relative;
  width: 75px;
  height: 100px;
  margin-bottom: 15px;
}

.loaderText {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--var-loaderText-color);
  margin-bottom: 25px;
}

.loader__bar {
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 50%;
  transform-origin: center bottom;
  border-radius: 50px 50px 50px 0;
}

.loader__bar:nth-child(1) {
  left: 0;
  background: var(--var-bar-1-bg);
  transform: scale(1, 0.2);
  -webkit-animation: barup1 4s infinite;
  animation: barup1 4s infinite;
}

.loader__bar:nth-child(2) {
  left: 15px;
  background: var(--var-bar-2-bg);
  transform: scale(1, 0.4);
  -webkit-animation: barup2 4s infinite;
  animation: barup2 4s infinite;
}

.loader__bar:nth-child(3) {
  left: 30px;
  background: var(--var-bar-3-bg);
  transform: scale(1, 0.6);
  -webkit-animation: barup3 4s infinite;
  animation: barup3 4s infinite;
}

.loader__bar:nth-child(4) {
  left: 45px;
  background: var(--var-bar-4-bg);
  transform: scale(1, 0.8);
  -webkit-animation: barup4 4s infinite;
  animation: barup4 4s infinite;
}

.loader__bar:nth-child(5) {
  left: 60px;
  background: var(--var-bar-5-bg);
  transform: scale(1, 1);
  -webkit-animation: barup5 4s infinite;
  animation: barup5 4s infinite;
}

.loader__ball {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: var(--var-bar-ball-bg);
  border-radius: 50%;
  -webkit-animation: ball 4s infinite;
  animation: ball 4s infinite;
}

@-webkit-keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  
  5% {
    transform: translate(8px, -14px);
  }
  
  10% {
    transform: translate(15px, -10px);
  }
  
  17% {
    transform: translate(23px, -24px);
  }
  
  20% {
    transform: translate(30px, -20px);
  }
  
  27% {
    transform: translate(38px, -34px);
  }
  
  30% {
    transform: translate(45px, -30px);
  }
  
  37% {
    transform: translate(53px, -44px);
  }
  
  40% {
    transform: translate(60px, -40px);
  }
  
  50% {
    transform: translate(60px, 0);
  }
  
  57% {
    transform: translate(53px, -14px);
  }
  
  60% {
    transform: translate(45px, -10px);
  }
  
  67% {
    transform: translate(37px, -24px);
  }
  
  70% {
    transform: translate(30px, -20px);
  }
  
  77% {
    transform: translate(22px, -34px);
  }
  
  80% {
    transform: translate(15px, -30px);
  }
  
  87% {
    transform: translate(7px, -44px);
  }
  
  90% {
    transform: translate(0, -40px);
  }
  
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  
  5% {
    transform: translate(8px, -14px);
  }
  
  10% {
    transform: translate(15px, -10px);
  }
  
  17% {
    transform: translate(23px, -24px);
  }
  
  20% {
    transform: translate(30px, -20px);
  }
  
  27% {
    transform: translate(38px, -34px);
  }
  
  30% {
    transform: translate(45px, -30px);
  }
  
  37% {
    transform: translate(53px, -44px);
  }
  
  40% {
    transform: translate(60px, -40px);
  }
  
  50% {
    transform: translate(60px, 0);
  }
  
  57% {
    transform: translate(53px, -14px);
  }
  
  60% {
    transform: translate(45px, -10px);
  }
  
  67% {
    transform: translate(37px, -24px);
  }
  
  70% {
    transform: translate(30px, -20px);
  }
  
  77% {
    transform: translate(22px, -34px);
  }
  
  80% {
    transform: translate(15px, -30px);
  }
  
  87% {
    transform: translate(7px, -44px);
  }
  
  90% {
    transform: translate(0, -40px);
  }
  
  100% {
    transform: translate(0, 0);
  }
}

@-webkit-keyframes barup1 {
  0% {
    transform: scale(1, 0.2);
  }
  
  40% {
    transform: scale(1, 0.2);
  }
  
  50% {
    transform: scale(1, 1);
  }
  
  90% {
    transform: scale(1, 1);
  }
  
  100% {
    transform: scale(1, 0.2);
  }
}

@keyframes barup1 {
  0% {
    transform: scale(1, 0.2);
  }
  
  40% {
    transform: scale(1, 0.2);
  }
  
  50% {
    transform: scale(1, 1);
  }
  
  90% {
    transform: scale(1, 1);
  }
  
  100% {
    transform: scale(1, 0.2);
  }
}

@-webkit-keyframes barup2 {
  0% {
    transform: scale(1, 0.4);
  }
  
  40% {
    transform: scale(1, 0.4);
  }
  
  50% {
    transform: scale(1, 0.8);
  }
  
  90% {
    transform: scale(1, 0.8);
  }
  
  100% {
    transform: scale(1, 0.4);
  }
}

@keyframes barup2 {
  0% {
    transform: scale(1, 0.4);
  }
  
  40% {
    transform: scale(1, 0.4);
  }
  
  50% {
    transform: scale(1, 0.8);
  }
  
  90% {
    transform: scale(1, 0.8);
  }
  
  100% {
    transform: scale(1, 0.4);
  }
}

@-webkit-keyframes barup3 {
  0% {
    transform: scale(1, 0.6);
  }
  
  100% {
    transform: scale(1, 0.6);
  }
}

@keyframes barup3 {
  0% {
    transform: scale(1, 0.6);
  }
  
  100% {
    transform: scale(1, 0.6);
  }
}

@-webkit-keyframes barup4 {
  0% {
    transform: scale(1, 0.8);
  }
  
  40% {
    transform: scale(1, 0.8);
  }
  
  50% {
    transform: scale(1, 0.4);
  }
  
  90% {
    transform: scale(1, 0.4);
  }
  
  100% {
    transform: scale(1, 0.8);
  }
}

@keyframes barup4 {
  0% {
    transform: scale(1, 0.8);
  }
  
  40% {
    transform: scale(1, 0.8);
  }
  
  50% {
    transform: scale(1, 0.4);
  }
  
  90% {
    transform: scale(1, 0.4);
  }
  
  100% {
    transform: scale(1, 0.8);
  }
}

@-webkit-keyframes barup5 {
  0% {
    transform: scale(1, 1);
  }
  
  40% {
    transform: scale(1, 1);
  }
  
  50% {
    transform: scale(1, 0.2);
  }
  
  90% {
    transform: scale(1, 0.2);
  }
  
  100% {
    transform: scale(1, 1);
  }
}

@keyframes barup5 {
  0% {
    transform: scale(1, 1);
  }
  
  40% {
    transform: scale(1, 1);
  }
  
  50% {
    transform: scale(1, 0.2);
  }
  
  90% {
    transform: scale(1, 0.2);
  }
  
  100% {
    transform: scale(1, 1);
  }
}

#overlay-wash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: rgba(255 255 255 / 50%);
    z-index:2;
}